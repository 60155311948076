import React from 'react'
import SEO from '../components/seo'

const PrivacyPolicy = () => (
  <>
    <SEO title="Política de Privacidade - Immortalis" />
    <section className="mw8-ns center pa3 mt4">
      <h1 className="fw5">Política de Privacidade Immortalis</h1>
      <p className="lh-copy f4 mb4">
        Nós respeitamos sua privacidade. Toda informação pessoal que você informa ao Immortalis será
        apenas mantida sob nosso domínio, utilizada somente para comunicação com você. Ao visitar o
        site Immortalis, você concorda com a política de privacidade apresentada abaixo.
      </p>
      <p className="lh-copy f4 mb4">Formas com as quais utilizamos seus dados pessoais:</p>
      <p className="lh-copy f4 mb4">
        Ao acessar o Immortalis para assinar o livro de visitas ou para publicar um tributo ou
        memorial, o Immortalis coletará apenas as seguintes informações pessoais: nome, endereço de
        e-mail, endereço de IP e foto pessoal (providenciada pela rede social utilizada para o
        acesso).
      </p>
      <p className="lh-copy f4 mb4">
        O Immortalis utiliza serviços de terceiros para o armazenamento de dados (textos, vídeos e
        imagens) de tributos e memoriais, além de sistema de processamento de pagamentos online,
        hospedagem, estatísticas da world wide web, correio e mensagens eletrônicas etc. Estes
        serviços receberão apenas as informações necessárias para a execução consistente dos tarefas
        acima citadas, e eles não estão autorizados a utilizar nenhuma informação pessoal para
        qualquer outro propósito que não o explicitamente listado neste documento. O Immortalis
        utiliza serviços de web analytics para coletar informações sobre o perfil dos usuários que
        visitam o site, e estes serviços podem utilizar suas informações (mas NUNCA seu nome, e-mail
        ou informações de pagamento) para providenciar anúncios que possam ser de seu interesse.
      </p>
      <p className="lh-copy f4 mb4">
        Em algumas ocasiões, nós coletamos dados por meio de cookies, web logs, web beacons (também
        conhecidos como pixels) e outras tecnologias de monitoramento com o mero objetivo de
        melhorar a experiência de nossos usuários. &quot;Cookies&quot; são pequenas quantidades de
        informação que são armazenadas pelo seu navegador que capturam suas atividades no nosso
        site. Web beacons são linhas de código adicionadas em uma página web ou email com o objetivo
        transmitir informações.
      </p>
      <p className="lh-copy f4 mb4">
        Nós temos um compromisso com a confidencialidade e segurança dos dados que você compartilha
        conosco. Nós utilizamos uma combinação de tecnologias eletrônicas, procedimentos e medidas
        organizacionais para proteger qualquer informação de acesso não autorizado. A segurança do
        serviço de pagamento online é garantida pelo PayPal, líder de seu setor, utilizando a camada
        de segurança SSL (Secure Socket Layer) de criptografia. Mesmo assim, nenhuma transmissão de
        dados via Internet é 100% segura, de forma que não podemos garantir a segurança de qualquer
        informação enviada ao Immortalis.
      </p>
      <p className="lh-copy f4 mb4">
        Para qualquer solicitação referente a seus dados pessoais e informações publicadas no
        Immortalis, envie-nos uma mensagem pelo nosso&nbsp;
        <a href="/#contact" className="black">
          formulário de contato
        </a>
        .
      </p>
      <p className="lh-copy f4 mb4">
        <span className="b">Data de Efetivação:</span>
        &nbsp;13 de Fevereiro de 2019.
        <br />
        <span className="b">Última revisão:</span>
        &nbsp;13 de Fevereiro de 2019.
      </p>
      <p className="lh-copy f4 mb4">
        Nossa política de privacidade pode ser atualizada em qualquer momento. Caso ocorra qualquer
        atualização e você possua uma conta no Immortalis, você será notificado por e-mail e poderá
        ler seu conteúdo em nosso site.
      </p>
    </section>
  </>
)

export default PrivacyPolicy
